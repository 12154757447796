import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

// OK
export const client = new ApolloClient({
  link: new HttpLink({
     uri: 'https://api.thegraph.com/subgraphs/name/devair1/maki-fantom' // fantom
    //uri: 'https://api2.makiswap.com/subgraphs/name/maki-mainnet/exchange', //heco
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

// OK
export const healthClient = new ApolloClient({
  link: new HttpLink({
     uri: 'https://api.thegraph.com/index-node/graphql',  // fantom
    //uri: 'https://api2.makiswap.com/health/graphql', // heco
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

// ???
export const stakingClient = new ApolloClient({
  link: new HttpLink({
    // uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
    uri: '',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

// OK
export const blockClient = new ApolloClient({
  link: new HttpLink({
     uri: 'https://api.thegraph.com/subgraphs/name/radioshackcreator/blocks-fantom', //fantom
    //uri: 'https://api2.makiswap.com/subgraphs/name/maki-mainnet/blocks', //heco
  }),
  cache: new InMemoryCache(),
})
